.dash-nav{
    height: 100%;
    padding: 20px;
    background: #032a45;
    color: white;
}
.img{

    width: 90px;
    height: 90px;
}
.img img{
    width: 100%;
    height: 100%;
}
.info{
    display: flex;
    background: #043e687b;
    /* justify-content: center; */
    align-items: center;
    gap: 15px;
    padding: 15px 3px 15px 15px;
    border-radius: 10px;
    margin-bottom: 30px;
    margin-top: 10px;
}
.info p{
    font-size: 17px;
    margin-top: 0;
    margin-bottom: 0;
}
.ball{
    width: 50px;
    border-radius: 50%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 500;
    background: rgb(154, 154, 154);
}
.link{
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border-left: 3px solid white;
    padding: 10px 15px;
    background: #1f4b74;
}
.text{
    font-weight: 600;
    color: black;
    font-size: 20px;
}
/* .box{
    border: 2px solid red;
} */